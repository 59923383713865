import Vue from 'vue'
import App from './App.vue'
import { VeeVal } from './VeeValidateHelper';
import VueScript2 from 'vue-script2';
import VModal from 'vue-js-modal';

Vue.use(VeeVal);
Vue.use(VueScript2);
Vue.use(VModal);


//import and globally register custom components
import Contact from "./components/Contact"
Vue.component('contact', Contact);
import ApplicationForm from "./components/ApplicationForm"
Vue.component('application-form', ApplicationForm);

Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')
