<template>
  <div>
    <header id="home">
      <div class="main-menu">
        <div class="navbar-wrapper">
          <div class="navbar navbar-inverse navbar-fixed-top" role="navigation">
            <div class="container">
              <div class="navbar-header">
                <button
                  type="button"
                  class="navbar-toggle"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                >
                  <span class="sr-only">Toggle Navigation</span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>
                <a href="#home" class="navbar-brand"
                  ><img
                    src="dist/assets/img/logos/Logo_main_hr_001.png"
                    alt="MOSHA CARE SERVICE LTD Logo"
                /></a>
              </div>

              <div class="navbar-collapse collapse">
                <ul class="nav navbar-nav navbar-right">
                  <li><a href="#home">Home</a></li>
                  <li><a href="#about">About</a></li>
                  <li><a href="#features">Services</a></li>
                  <li><a href="#testimonials">Our Values</a></li>
                  <li><a href="#faq">Jobs</a></li>
                  <li><a href="#footer">Contact</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="slider">
        <div id="fawesome-carousel" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators indicatior2">
            <li
              data-target="#fawesome-carousel"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#fawesome-carousel" data-slide-to="1"></li>
            <li data-target="#fawesome-carousel" data-slide-to="2"></li>
            <li data-target="#fawesome-carousel" data-slide-to="3"></li>
          </ol>
          <div class="carousel-inner" role="listbox">
            <div class="item active">
              <img
                class="stam-carousel-image"
                src="dist/assets/img/CareImages/app_banner_002.png"
                alt="MOSHA CARE SERVICE LTD BANNER 1"
              />
              <div class="carousel-caption">
                <!--<h1 class="wow fadeInLeft item-top">PERSONAL CARE IN THE <br /> COMFORT OF YOUR HOME</h1>-->
                <!--<div class="slider-btn wow fadeIn">
                                    <a href="#features" class="btn btn-learn">More...</a>
                                </div>-->
              </div>
            </div>
            <div class="item">
              <img
                class="stam-carousel-image"
                src="dist/assets/img/CareImages/icare_banner_008.png"
                alt="MOSHA CARE SERVICE LTD BANNER 2"
              />
              <div class="carousel-caption">
                <h1 class="wow fadeInLeft item-top">
                  PERSONAL CARE IN THE <br />
                  COMFORT OF YOUR HOME
                </h1>
                <div class="slider-btn wow fadeIn">
                  <a href="#features" class="btn btn-learn">More...</a>
                </div>
              </div>
            </div>
            <div class="item">
              <img
                class="stam-carousel-image"
                src="dist/assets/img/CareImages/icare_banner_002.png"
                alt="MOSHA CARE SERVICE LTD BANNER 3"
              />
              <div class="carousel-caption">
                <h1 class="wow fadeInLeft item-top">LIVE-IN CARE</h1>
                <div class="slider-btn wow fadeIn">
                  <a href="#features" class="btn btn-learn">More...</a>
                </div>
              </div>
            </div>
            <div class="item">
              <img
                class="stam-carousel-image"
                src="dist/assets/img/CareImages/icare_banner_003.png"
                alt="MOSHA CARE SERVICE LTD BANNER 5"
              />
              <div class="carousel-caption carousel-caption-contact">
                <h1 class="wow fadeInLeft item-top">
                  CALL US NOW ON: 07947 838 641
                </h1>
                <h3 class="wow fadeInLeft item-top">
                  E-MAIL: <br />
                  contactus@moshacare.com
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section id="about" class="site-padding-about">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="about-image wow fadeInLeft">
              <img
                src="dist/assets/img/CareImages/icare_carer_001.jpg"
                alt="ABOUT MOSHA CARE SERVICE LTD IMAGE"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="about-text wow fadeInRight">
              <h3>
                <span style="color: #2c5aa0">MOSHA CARE</span>
                <span style="color: #f6ab12"> SERVICE LTD</span>
              </h3>
              <p>
                MOSHA CARE SERVICE LTD is adomiciliary care agency regulated to
                provide personal care. Key personal and care needs provided are:
              </p>
              <ul class="fa-ul">
                <li>
                  <i class="fa-li fa fa-check-square"></i>Dressing and
                  undressing, Bathing, Washing, Shaving and Oral hygiene
                </li>                
                <li>
                  <i class="fa-li fa fa-check-square"></i>Toilet and continence
                  requirements
                </li>
                <li>
                  <i class="fa-li fa fa-check-square"></i>Medication management
                </li>
                <li><i class="fa-li fa fa-check-square"></i>Manual handling</li>
                <li>
                  <i class="fa-li fa fa-check-square"></i>Eating and Drinking,
                  Preparation of meals, Cleaning and vacuuming the house, Dish
                  washing and emptying the bins
                </li>
                <li>
                  <i class="fa-li fa fa-check-square"></i>Grocery shopping,
                  Laundry and ironing
                </li>
              </ul>
              <p>
                We provide services for the following bands of Service Users:
                Older people, Adults, Young adults, Physical disability and
                Dementia.
              </p>
              <p>
                The following Care and support services are provided by The
                Agency: Alzheimer's, Aids/HIV Cancer care, Palliative care,
                Cerebral Palsy, Motor neurone disease, Multiple sclerosis,
                Parkinson's disease, Stroke and Day care. Service User care
                plans are reviewed on an individual basis, according to assessed
                need, but at least every month.
              </p>
              <p>
                During this journey we work closely with every service user to
                develop their life skills, taking controlled risks, becoming
                independent, gaining confidence.
              </p>
              <p>
                We provide great care in the comfort of your own home, we are
                reliable, try us today and you will not forget us in a hurry!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="features" class="site-padding">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="title">
              <h3>
                <span style="color: #2c5aa0">Our </span
                ><span style="color: #f6ab12">Services</span>
              </h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <p style="margin-bottom: 40px">
              We strive to provide our clients with the highest and most
              reliable level of support available. We provide care to meet a
              comprehensive range of client needs in order to help adults stay
              independent and live in their own homes or supported living
              schemes. We aim to involve Service Users and their loved ones in
              the assessment and implementation of their care needs ensuring
              that the service is provided and run in the best interest of
              Service Users.
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <div class="single-feature wow fadeInLeft">
              <div class="row">
                <div class="col-md-2">
                  <div class="feature-icon">
                    <i class="fa fa-user-circle"></i>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="feature-text">
                    <h4>Personal Care</h4>
                    <p>
                      Our professional and caring staff are able to provide a
                      wide range of personal care services allowing our clients
                      to maintain their dignity and respect. Our services
                      include:
                    </p>
                    <ul class="fa-ul">
                      <li>
                        <i class="fa-li fa fa-check-square"></i
                        ><b>Assisting clients with their personal hygiene</b>
                        Personal care is a regulated activity, which means any
                        aspect of personal care services are required to be
                        registered and are inspected and rated. Personal care
                        consists of any aspect or any level of personal hygiene
                        i.e. washing, bathing, continence care etc.
                      </li>
                      <li>
                        <i class="fa-li fa fa-check-square"></i
                        ><b>Assistance with dressing</b>
                        This includes helping clients to get dressed, choosing
                        appropriate clothing or to give full support in dressing
                        or just helping with socks or surgical stockings.
                      </li>
                      <li>
                        <i class="fa-li fa fa-check-square"></i
                        ><b>Support with medication</b>
                        We can support with full administration of medication,
                        prompting (or reminding) medication where appropriate
                        and safe to do. We can also support with collection and
                        ordering of prescribed medication. We can provide the
                        support you need even if you just require support to
                        open medication.
                      </li>
                      <li>
                        <i class="fa-li fa fa-check-square"></i
                        ><b>Assistance with meals</b>
                        We can support with preparing foods at any level.
                        Support with helping to eat can also be included.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="single-feature wow fadeInLeft">
              <div class="row">
                <div class="col-md-2">
                  <div class="feature-icon">
                    <i class="fa fa-wheelchair-alt"></i>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="feature-text">
                    <h4>Physical Disabilities Care</h4>
                    <p>
                      Just because you may have a physical disability does not
                      mean you can't enjoy a full and active life living in your
                      own home. Our professional carers will listen to your
                      needs and put together a bespoke support package to meet
                      your requirements. This can range from once a week visits
                      to daily visits or even live in care. We can help you with
                      a wide range of tasks including domestic duties around the
                      home, personal care and washing, social appointments and
                      hobbies. Indeed, our support packages are aimed at helping
                      you stay independent in the comfort of your own home,
                      allowing you to maintain your everyday routine.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="single-feature wow fadeInRight">
              <div class="row">
                <div class="col-md-2">
                  <div class="feature-icon">
                    <i class="fa fa-bitbucket"></i>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="feature-text">
                    <h4>Domestic Care</h4>
                    <p>
                      We provide domestic support at all levels from emptying a
                      bin and replacing a light bulb to cleaning a home and
                      completing all laundry requirements. Our professional
                      staff have been trained to maintain high standards of
                      hygiene and cleanliness to ensure our clients wellbeing
                      and good health is maintained. We're more than happy to
                      help with the following:
                    </p>
                    <ul class="fa-ul">
                      <li>
                        <i class="fa-li fa fa-check-square"></i>Food and drink
                        preparation
                      </li>
                      <li>
                        <i class="fa-li fa fa-check-square"></i>Food shopping
                      </li>
                      <li>
                        <i class="fa-li fa fa-check-square"></i>Cleaning and
                        tidying up
                      </li>
                      <li>
                        <i class="fa-li fa fa-check-square"></i>Washing up
                      </li>
                      <li>
                        <i class="fa-li fa fa-check-square"></i>Laundry and
                        ironing
                      </li>
                      <li><i class="fa-li fa fa-check-square"></i>Vacuuming</li>
                      <li>
                        <i class="fa-li fa fa-check-square"></i>Other light
                        household duties
                      </li>
                    </ul>
                    <p>
                      Whether you or your family member require help every day
                      or once a week, help with the household chores can provide
                      great relief, especially for people who may suffer from
                      mobility around the home.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="single-feature wow fadeInLeft">
              <div class="row">
                <div class="col-md-2">
                  <div class="feature-icon">
                    <i class="fa fa-coffee"></i>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="feature-text">
                    <h4>Respite Care</h4>
                    <p>
                      Our respite care is available to any family member or
                      friend that has become a carer. We acknowledge the vital
                      role they play in people's lives and will support them by
                      offering time off and looking after their loved ones, in
                      the way they do. This could be anything from an afternoon,
                      a day or a month at a time.
                    </p>
                    <p>
                      It's important that you make time for yourself, both for
                      your own wellbeing and health but also to revitalise
                      yourself to carry on caring for your loved one. We
                      appreciate that speaking up and asking for some help is a
                      hard thing to do for many people, but we have helped many
                      family carers by giving them a break and have seen
                      first-hand the positive effect it has had on both their
                      own lives and that of the person they've been caring for.
                    </p>
                    <p>
                      Contact our office and we will answer any questions that
                      you may have about receiving respite care and how it all
                      works.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="single-feature wow fadeInLeft">
              <div class="row">
                <div class="col-md-2">
                  <div class="feature-icon">
                    <i class="fa fa-smile-o"></i>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="feature-text">
                    <h4>Social Care</h4>
                    <p>
                      We actively promote social care and encourage socialising
                      in any appropriate form. We can escort and remain with you
                      to participate in any clubs, groups, days centres or
                      colleges. We fully believe that social interaction is
                      paramount in the care and development and rehabilitation
                      of our clients. If it's basic shopping right through to an
                      activity centre, we will provide the right level of
                      support you require.
                    </p>
                    <p>
                      With <b>MOSHA CARE SERVICE LTD</b> there is no need to
                      feel isolated anymore. Our carers thoroughly enjoy getting
                      to know our clients and forming strong relationships with
                      them. We'll help build their confidence and allow them to
                      feel comfortable in every day social situations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="single-feature wow fadeInRight">
              <div class="row">
                <div class="col-md-2">
                  <div class="feature-icon">
                    <i class="fa fa-home"></i>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="feature-text">
                    <h4>24/7 Live-in Care</h4>
                    <p>
                      Our live-in care package gives your loved ones fulltime
                      support and care in the comfort of their own home by
                      matching your loved one with a dedicated care professional
                      who moves into their home.
                    </p>
                    <p>
                      <b>MOSHA CARE SERVICE LTD</b> provides experienced, vetted
                      carers that help with everything from personal hygiene to
                      daily household tasks, ensuring your relative is safe and
                      happy in place they love most, their own home.
                    </p>
                    <p>
                      We understand your loved one's safety is paramount. That's
                      why we go to great lengths to ensure their carer is
                      someone the whole family can trust. All the professionals
                      we work with have background checks, referencing and
                      certificates to prove they reach the standards you expect.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="testimonials">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="title">
              <h3>Our Values &amp; Principles</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="carouselButtons">
              <button
                id="playValues"
                type="button"
                class="btn btn-default btn-xs"
              >
                <span class="fa fa-play-circle-o"></span>
              </button>
              <button
                id="pauseValues"
                type="button"
                class="btn btn-default btn-xs"
              >
                <span class="fa fa-pause-circle-o"></span>
              </button>
            </div>
          </div>
        </div>
        <div
          id="fawesome-carousel-two"
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#fawesome-carousel-two"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#fawesome-carousel-two" data-slide-to="1"></li>
            <li data-target="#fawesome-carousel-two" data-slide-to="2"></li>
            <li data-target="#fawesome-carousel-two" data-slide-to="3"></li>
          </ol>

          <div class="carousel-inner" role="listbox">
            <div class="item active">
              <div class="row">
                <div class="col-sm-6">
                  <div class="single-testimonials">
                    <div class="text">
                      <p>
                        <b>Privacy</b>
                        The right of individuals to be left alone or undisturbed
                        and free from intrusion into their affairs. This is
                        taken into account in the formulation of Care Plans, and
                        will only be overridden in exceptional circumstances,
                        and with the knowledge of the service user or their
                        advocate.
                      </p>
                    </div>

                    <div class="profile-image">
                      <span class="fa fa-thumbs-o-up"></span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="single-testimonials">
                    <div class="text">
                      <p>
                        <b>Confidentiality</b>
                        Service user confidentiality is, wherever possible,
                        maintained. On occasion, it will be necessary, for the
                        benefit of the service user or others to share personal
                        information with either other professionals or
                        organisations. Where possible, service users are
                        consulted and their views taken into account.
                      </p>
                    </div>

                    <div class="profile-image">
                      <span class="fa fa-thumbs-o-up"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Slide 2 -->
            <div class="item">
              <div class="row">
                <div class="col-sm-6">
                  <div class="single-testimonials">
                    <div class="text">
                      <p>
                        <b>Dignity</b>
                        All individuals, whatever their circumstances, have the
                        right to be treated with dignity and respect.
                      </p>
                      <p>
                        <b>Choice</b>
                        Services are designed to be accessible and flexible,
                        promoting ordinary lifestyles and based upon client's
                        own choices. Individual choice is promoted, within the
                        limits imposed by service constraints.
                      </p>
                    </div>

                    <div class="profile-image">
                      <span class="fa fa-thumbs-o-up"></span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="single-testimonials">
                    <div class="text">
                      <p>
                        <b>Anti-discrimination</b>
                        Many clients, because of their circumstances, (e.g. age,
                        disability, gender, marital status, sexual orientation,
                        culture, religion or nationality), may find themselves
                        in circumstances in which discrimination can occur.
                        MOSHA CARE SERVICE LTD designs its services and delivery
                        to, wherever possible, promote equality and counteract
                        discrimination where it arises.
                      </p>
                    </div>

                    <div class="profile-image">
                      <span class="fa fa-thumbs-o-up"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Next Slide -->
            <!-- Slide 3 -->
            <div class="item">
              <div class="row">
                <div class="col-sm-6">
                  <div class="single-testimonials">
                    <div class="text">
                      <p>
                        <b>Communication</b>
                        Many clients, because of their circumstances, (e.g. age,
                        disability, gender, marital status, sexual orientation,
                        culture, religion or nationality), may find themselves
                        in circumstances in which discrimination can occur.
                        MOSHA CARE SERVICE LTD designs its services and delivery
                        to, wherever possible, promote equality and counteract
                        discrimination where it arises.
                      </p>
                    </div>

                    <div class="profile-image">
                      <span class="fa fa-thumbs-o-up"></span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="single-testimonials">
                    <div class="text">
                      <p>
                        <b>Independence</b>
                        Clients are enabled to act independently. Services are
                        aimed at maximising the individual's capacity for
                        self-care and mobility. Every individual, whatever their
                        circumstances, has the potential for development, and
                        the right to hold personal aspirations. Within the
                        boundaries set by legislation and professional social
                        work ethics, service users are encouraged in achieving
                        their personal goals.
                      </p>
                    </div>

                    <div class="profile-image">
                      <span class="fa fa-thumbs-o-up"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Slide 4 -->
            <div class="item">
              <div class="row">
                <div class="col-sm-6">
                  <div class="single-testimonials">
                    <div class="text">
                      <p>
                        <b>Rights</b>
                        The rights of citizenship are safeguarded for all
                        service users. Work should be conducted in a manner that
                        facilitates empowerment and makes appropriate use of
                        advocacy.
                      </p>
                      <p>
                        <b>Responsibilities</b>
                        Clients are expected to accept appropriate
                        responsibilities, taking into account their particular
                        abilities and circumstances. These may include having
                        due regard for others, for property or for participation
                        in Care Plans.
                      </p>
                    </div>

                    <div class="profile-image">
                      <span class="fa fa-thumbs-o-up"></span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="single-testimonials">
                    <div class="text">
                      <p>
                        <b>Family Support</b>
                        The value of the contribution of client's family support
                        is fully recognised. The needs of the client we are
                        providing, or intending to provide, regular or
                        substantial care is, where requested, assessed
                        independently of the needs of the client. The outcome of
                        the assessment is taken into account when agreeing Care
                        Plans.
                      </p>
                    </div>

                    <div class="profile-image">
                      <span class="fa fa-thumbs-o-up"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="faq">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="title">
              <h3><span style="color: #f6ab12">Jobs</span></h3>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="panel-group" id="accordion">
              <div class="faqHeader">
                <h2>
                  Job Application - Care Assistants / Support Workers / Nurses /
                  Nanny
                </h2>
              </div>

              <div class="panel panel-primary">
                <div class="panel-heading">
                  <h3 class="panel-title">
                    <a
                      class="accordion-toggle"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseOne"
                      >APPLY ONLINE</a
                    >
                  </h3>
                </div>
                <div id="collapseOne" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p class="stam-application-info">
                      Complete the application form below and make sure all
                      questions marked as required are filled-in.<br />
                      A copy of the completed online application form will be
                      sent to your e-mail address, so please check that your
                      e-mail address is correct.<br />
                      Click the submit button when you have finished completing
                      the form.<br />
                      We will review your application and contact you within
                      three working days.
                    </p>
                    <application-form></application-form>
                  </div>
                </div>
              </div>

              <div class="panel panel-primary">
                <div class="panel-heading">
                  <h3 class="panel-title">
                    <a
                      class="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseTwo"
                      >DOWNLOAD APPLICATION PACK</a
                    >
                  </h3>
                </div>
                <div id="collapseTwo" class="panel-collapse collapse in">
                  <div class="panel-body">
                    <div class="col-sm-4">
                      <p>
                        To apply for this job, you may download and complete the
                        application pack and e-mail to
                        <span style="color: #0094ff"
                          >contactus@moshacare.com
                        </span>
                        or post to the address detailed below.
                      </p>
                    </div>
                    <div
                      class="col-sm-8 wow rollIn center"
                      data-wow-duration="1s"
                      data-wow-delay="1s"
                    >
                      <div class="file-download-app-pack">
                        <div class="file-download-app-pack-title">
                          Application pack
                        </div>
                        <span
                          class="file-download-app-pack-span fa fa-file-pdf-o"
                        ></span
                        ><br />
                        <a
                          href="home/download?filename=MOSHA_CARE_SERVICE_LTD_ApplicationPack_v2.docx"
                          class="file-download-app-pack-btn btn btn-primary btn-lg"
                          ><span
                            class="fa fa-cloud-download file-download-app-pack-btn-logo"
                            role="button"
                          ></span
                          ><span class="file-download-app-pack-btn-text"
                            >Download</span
                          ></a
                        >
                      </div>
                    </div>
                    <!-- <div class="col-sm-4 wow rollIn center" data-wow-duration="1s" data-wow-delay="1s">
                                            <div class="file-download-app-pack">
                                                <div class="file-download-app-pack-title">
                                                    Time-sheet
                                                </div>
                                                <span class="file-download-app-pack-span fa fa-file-pdf-o"></span><br />
                                                <a href="home/download?filename=ICARE_TimeSheet_02.pdf" class="file-download-app-pack-btn btn btn-primary btn-lg"><span class="fa fa-cloud-download file-download-app-pack-btn-logo" role="button"></span><span class="file-download-app-pack-btn-text">Download</span></a>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contact-us">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="title">
              <h3>
                <span style="color: #2c5aa0">Our </span
                ><span style="color: #f6ab12">Main office</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div
        class="googlemap"
        data-latlng="52.594860,-1.165740"
        data-scrollwheel="false"
        data-icon="dist/assets/img/map_marker.png"
        data-zoom="17"
        data-theme="Default"
      >
        <div class="marker-content">
          <h5>MOSHA CARE SERVICE LTD</h5>
          <p>
            12 Kings Lock Close<br />
            LE2 9GW<br />
            Leicester<br />
            United Kingdom
          </p>
        </div>
      </div>
      <div class="contact">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <contact></contact>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer id="footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-3">
            <div class="single-footer">
              <div class="footer-logo">
                <img
                  src="dist/assets/img/logos/logo_main_001.png"
                  alt="MOSHA CARE SERVICE LTD FOOTER LOGO"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="single-footer">
              <h4>Contact us</h4>
              <p>
                12 Kings Lock Close<br />
                LE2 9GW<br />
                Leicester<br />
                United Kingdom
              </p>
              <p>E-mail: contactus@moshacare.com <br /></p>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="single-footer">
              <h4>Opening times</h4>
              <p>
                Mon - Fri:<br />
                09 :00am - 17 :00pm<br />
                Sat: Closed<br />
                Sun: Closed<br />
                Mobile: 07947 838 641
              </p>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="single-footer">
              <h4>Services</h4>
              <ul>
                <li><a href="#features">DOMESTIC CARE</a></li>
                <li><a href="#features">PERSONAL CARE</a></li>
                <li><a href="#features">PHYSICAL DISABILITIES CARE</a></li>
                <li><a href="#features">RESPITE CARE</a></li>
                <li><a href="#features">LIVE-IN CARE</a></li>
                <li><a href="#features">SUPPORT FOR YOUNGER PEOPLE</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="copy-text">
              <p>
                For your software needs contact
                <span style="color: #ffffff">programmer@softmighty.com</span> :
                mobile-apps, web-apps, websites, data-driven apps, consultancy
                and IT Strategy advisory services.
              </p>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="footer-menu pull-right">
              <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#features">Services</a></li>
                <li><a href="#faq">Jobs</a></li>
              </ul>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="social">
              <ul>
                <li>
                  <a href="#" target="_blank"><i class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li>
                  <a
                    style="color: #ffffff"
                    href="https://webmail.1and1.co.uk/"
                    target="_blank"
                    >Staff E-mails</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <script2
      src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDQK_5EIUpJzdD7TAq8Has6oNrQpzQAmn8&callback=initMap"
    ></script2>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {},
  computed: {},
  //   mounted() {
  //       window.wow.init();
  //   }
};
</script>
