<template>
  <div class="custom-form">
    <div class="panel-body">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Position Information</h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_Position') }"
          >
            <label for="app_Position"
              >What position are you applying for ?</label
            >
            <select
              id="app_Position"
              name="app_Position"
              v-model="fdata.app_Position"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">Care Assistant / Support Worker</option>
              <option value="97">Other</option>
            </select>
            <span class="help-block">{{ errors.first("app_Position") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PositionText') }"
          >
            <label for="app_PositionText">Please specify</label>
            <input
              id="app_PositionText"
              name="app_PositionText"
              v-model.trim="fdata.app_PositionText"
              v-validate="{
                required: !disableCriteriaMet_app_PositionText,
                max: 100,
              }"
              v-bind:disabled="disableCriteriaMet_app_PositionText"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_PositionText")
            }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Personal details</h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_Title') }"
          >
            <label for="app_Title">Title</label>
            <select
              id="app_Title"
              name="app_Title"
              v-model="fdata.app_Title"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">Ms</option>
              <option value="2">Miss</option>
              <option value="3">Mrs</option>
              <option value="4">Mr</option>
              <option value="5">Dr</option>
              <option value="97">Other</option>
            </select>
            <span class="help-block">{{ errors.first("app_Title") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_TitleText') }"
          >
            <label for="app_TitleText">Specify your title</label>
            <input
              id="app_TitleText"
              name="app_TitleText"
              v-model.trim="fdata.app_TitleText"
              v-validate="{
                required: !disableCriteriaMet_app_TitleText,
                max: 50,
              }"
              v-bind:disabled="disableCriteriaMet_app_TitleText"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_TitleText") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_FirstName') }"
          >
            <label for="app_FirstName">First name</label>
            <input
              id="app_FirstName"
              name="app_FirstName"
              v-model.trim="fdata.app_FirstName"
              v-validate.initial="{ required: true, max: 50, valid_name: true }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_FirstName") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_MiddleName') }"
          >
            <label for="app_MiddleName">Middle name(s)</label>
            <input
              id="app_MiddleName"
              name="app_MiddleName"
              v-model.trim="fdata.app_MiddleName"
              v-validate="{ max: 100, valid_name: true }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_MiddleName") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_Surname') }"
          >
            <label for="app_Surname">Surname</label>
            <input
              id="app_Surname"
              name="app_Surname"
              v-model.trim="fdata.app_Surname"
              v-validate.initial="{ required: true, max: 50, valid_name: true }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_Surname") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_DateOfBirth') }"
          >
            <label for="app_DateOfBirth"
              >Date of birth ( example 28-Sep-1982 )</label
            >
            <input
              id="app_DateOfBirth"
              name="app_DateOfBirth"
              v-model.trim="fdata.app_DateOfBirth"
              v-validate.initial="{
                required: true,
                valid_date_format: true,
                age_check: { opt: 'Minimum', ageLimit: 16 },
              }"
              placeholder="DD-MMM-YYYY"
              maxlength="11"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_DateOfBirth")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_MobileNumber') }"
          >
            <label for="app_MobileNumber">Mobile / telephone number</label>
            <input
              id="app_MobileNumber"
              name="app_MobileNumber"
              v-model.trim="fdata.app_MobileNumber"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_MobileNumber")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_Email') }"
          >
            <label for="app_Email"
              >Your E-mail (Please note: a copy of your application will be sent
              to this e-mail when you submit)</label
            >
            <input
              id="app_Email"
              name="app_Email"
              v-model.trim="fdata.app_Email"
              v-validate.initial="{ required: true, max: 50, email: true }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_Email") }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Your Address</h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_Address1') }"
          >
            <label for="app_Address1"
              >House number / First line of address</label
            >
            <input
              id="app_Address1"
              name="app_Address1"
              v-model.trim="fdata.app_Address1"
              v-validate.initial="{ required: true, max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_Address1") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_Address2') }"
          >
            <label for="app_Address2">Street / Road</label>
            <input
              id="app_Address2"
              name="app_Address2"
              v-model.trim="fdata.app_Address2"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_Address2") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_Address3') }"
          >
            <label for="app_Address3">Locality</label>
            <input
              id="app_Address3"
              name="app_Address3"
              v-model.trim="fdata.app_Address3"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_Address3") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_Town') }"
          >
            <label for="app_Town">Town / City</label>
            <input
              id="app_Town"
              name="app_Town"
              v-model.trim="fdata.app_Town"
              v-validate.initial="{ required: true, max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_Town") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_County') }"
          >
            <label for="app_County">County</label>
            <input
              id="app_County"
              name="app_County"
              v-model.trim="fdata.app_County"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_County") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_Postcode') }"
          >
            <label for="app_Postcode">Postcode</label>
            <input
              id="app_Postcode"
              name="app_Postcode"
              v-model.trim="fdata.app_Postcode"
              v-validate.initial="{
                required: true,
                max: 10,
                valid_uk_postcode: true,
              }"
              maxlength="10"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_Postcode") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_Country') }"
          >
            <label for="app_Country">Country</label>
            <select
              id="app_Country"
              name="app_Country"
              v-model="fdata.app_Country"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">United Kingdom (UK)</option>
            </select>
            <span class="help-block">{{ errors.first("app_Country") }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_EmpNI') }"
          >
            <label for="app_EmpNI"
              >Your National Insurance Number (N. I. Number)</label
            >
            <input
              id="app_EmpNI"
              name="app_EmpNI"
              v-model.trim="fdata.app_EmpNI"
              v-validate="{ max: 20 }"
              maxlength="20"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_EmpNI") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_NextOfKin') }"
          >
            <label for="app_NextOfKin"
              >Name of your Next of Kin / Emergency Contact</label
            >
            <input
              id="app_NextOfKin"
              name="app_NextOfKin"
              v-model.trim="fdata.app_NextOfKin"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_NextOfKin") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_NextOfKinRel') }"
          >
            <label for="app_NextOfKinRel"
              >Next of Kin / Emergency Contact's relationship to you</label
            >
            <input
              id="app_NextOfKinRel"
              name="app_NextOfKinRel"
              v-model.trim="fdata.app_NextOfKinRel"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_NextOfKinRel")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_NextOfKinTel') }"
          >
            <label for="app_NextOfKinTel"
              >Next of Kin / Emergency Contact's mobile / telephone
              number</label
            >
            <input
              id="app_NextOfKinTel"
              name="app_NextOfKinTel"
              v-model.trim="fdata.app_NextOfKinTel"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_NextOfKinTel")
            }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Are you applying for:</h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_EmpTemp') }"
          >
            <label for="app_EmpTemp"
              >Temporary work – such as summer or holiday work ?</label
            >
            <select
              id="app_EmpTemp"
              name="app_EmpTemp"
              v-model="fdata.app_EmpTemp"
              class="form-control"
            >
              <option></option>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
            <span class="help-block">{{ errors.first("app_EmpTemp") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_EmpRegPartTime') }"
          >
            <label for="app_EmpRegPartTime">Regular part-time work ?</label>
            <select
              id="app_EmpRegPartTime"
              name="app_EmpRegPartTime"
              v-model="fdata.app_EmpRegPartTime"
              class="form-control"
            >
              <option></option>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
            <span class="help-block">{{
              errors.first("app_EmpRegPartTime")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_EmpRegFullTime') }"
          >
            <label for="app_EmpRegFullTime">Regular full-time work ?</label>
            <select
              id="app_EmpRegFullTime"
              name="app_EmpRegFullTime"
              v-model="fdata.app_EmpRegFullTime"
              class="form-control"
            >
              <option></option>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
            <span class="help-block">{{
              errors.first("app_EmpRegFullTime")
            }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Days / hours available:</h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvMonday') }"
          >
            <label for="app_AvMonday">Monday (in hours)</label>
            <input
              id="app_AvMonday"
              name="app_AvMonday"
              v-model.trim="fdata.app_AvMonday"
              v-validate="{ decimal: 1, max: 3, between: [1, 24] }"
              maxlength="3"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_AvMonday") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvTuesday') }"
          >
            <label for="app_AvTuesday">Tuesday (in hours)</label>
            <input
              id="app_AvTuesday"
              name="app_AvTuesday"
              v-model.trim="fdata.app_AvTuesday"
              v-validate="{ decimal: 1, max: 3, between: [1, 24] }"
              maxlength="3"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_AvTuesday") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvWednesday') }"
          >
            <label for="app_AvWednesday">Wednesday (in hours)</label>
            <input
              id="app_AvWednesday"
              name="app_AvWednesday"
              v-model.trim="fdata.app_AvWednesday"
              v-validate="{ decimal: 1, max: 3, between: [1, 24] }"
              maxlength="3"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_AvWednesday")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvThursday') }"
          >
            <label for="app_AvThursday">Thursday (in hours)</label>
            <input
              id="app_AvThursday"
              name="app_AvThursday"
              v-model.trim="fdata.app_AvThursday"
              v-validate="{ decimal: 1, max: 3, between: [1, 24] }"
              maxlength="3"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_AvThursday") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvFriday') }"
          >
            <label for="app_AvFriday">Friday (in hours)</label>
            <input
              id="app_AvFriday"
              name="app_AvFriday"
              v-model.trim="fdata.app_AvFriday"
              v-validate="{ decimal: 1, max: 3, between: [1, 24] }"
              maxlength="3"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_AvFriday") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvSaturday') }"
          >
            <label for="app_AvSaturday">Saturday (in hours)</label>
            <input
              id="app_AvSaturday"
              name="app_AvSaturday"
              v-model.trim="fdata.app_AvSaturday"
              v-validate="{ decimal: 1, max: 3, between: [1, 24] }"
              maxlength="3"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_AvSaturday") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvSunday') }"
          >
            <label for="app_AvSunday">Sunday (in hours)</label>
            <input
              id="app_AvSunday"
              name="app_AvSunday"
              v-model.trim="fdata.app_AvSunday"
              v-validate="{ decimal: 1, max: 3, between: [1, 24] }"
              maxlength="3"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_AvSunday") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvStartDate') }"
          >
            <label for="app_AvStartDate"
              >If employed, on what date can you start working</label
            >
            <input
              id="app_AvStartDate"
              name="app_AvStartDate"
              v-model.trim="fdata.app_AvStartDate"
              v-validate="{
                valid_date_format: true,
                current_date_compare: { opt: 'gteq' },
              }"
              placeholder="DD-MMM-YYYY"
              maxlength="11"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_AvStartDate")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvCanWorkEv') }"
          >
            <label for="app_AvCanWorkEv">Can you work evenings ?</label>
            <select
              id="app_AvCanWorkEv"
              name="app_AvCanWorkEv"
              v-model="fdata.app_AvCanWorkEv"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
            <span class="help-block">{{
              errors.first("app_AvCanWorkEv")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvDLtype') }"
          >
            <label for="app_AvDLtype"
              >Do you have a full or provisional UK Driving Licence ?</label
            >
            <select
              id="app_AvDLtype"
              name="app_AvDLtype"
              v-model="fdata.app_AvDLtype"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">Full</option>
              <option value="2">Provisional</option>
              <option value="3">None</option>
            </select>
            <span class="help-block">{{ errors.first("app_AvDLtype") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvOwnTpt') }"
          >
            <label for="app_AvOwnTpt">Do you have your own transport ?</label>
            <select
              id="app_AvOwnTpt"
              name="app_AvOwnTpt"
              v-model="fdata.app_AvOwnTpt"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
            <span class="help-block">{{ errors.first("app_AvOwnTpt") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvDBScheck') }"
          >
            <label for="app_AvDBScheck"
              >Do you have an On-Line DBS which we can check; if so please
              provide the Reference Number ?</label
            >
            <select
              id="app_AvDBScheck"
              name="app_AvDBScheck"
              v-model="fdata.app_AvDBScheck"
              class="form-control"
            >
              <option></option>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
            <span class="help-block">{{ errors.first("app_AvDBScheck") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AvDBSref') }"
          >
            <label for="app_AvDBSref">DBS Update Service Ref No</label>
            <input
              id="app_AvDBSref"
              name="app_AvDBSref"
              v-model.trim="fdata.app_AvDBSref"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_AvDBSref") }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">
            Employment history: Present / most recent employer's details
          </h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempName') }"
          >
            <label for="app_PempName">Employer's name</label>
            <input
              id="app_PempName"
              name="app_PempName"
              v-model.trim="fdata.app_PempName"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_PempName") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempTel') }"
          >
            <label for="app_PempTel">Telephone number</label>
            <input
              id="app_PempTel"
              name="app_PempTel"
              v-model.trim="fdata.app_PempTel"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_PempTel") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempBType') }"
          >
            <label for="app_PempBType">Type of business</label>
            <input
              id="app_PempBType"
              name="app_PempBType"
              v-model.trim="fdata.app_PempBType"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_PempBType") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempPos') }"
          >
            <label for="app_PempPos">Post held</label>
            <input
              id="app_PempPos"
              name="app_PempPos"
              v-model.trim="fdata.app_PempPos"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_PempPos") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempFromDate') }"
          >
            <label for="app_PempFromDate">From: (date)</label>
            <input
              id="app_PempFromDate"
              name="app_PempFromDate"
              v-model.trim="fdata.app_PempFromDate"
              v-validate="{
                valid_date_format: true,
                current_date_compare: { opt: 'lteq' },
              }"
              data-vv-validate-on="blur"
              placeholder="DD-MMM-YYYY"
              maxlength="11"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_PempFromDate")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempToDate') }"
          >
            <label for="app_PempToDate">To: (date)</label>
            <input
              id="app_PempToDate"
              name="app_PempToDate"
              v-model.trim="fdata.app_PempToDate"
              v-validate="{
                valid_date_format: true,
                lookup_compare: {
                  opt: 'gteq',
                  targetValue: fdata.app_PempFromDate,
                  dataType: 'number',
                },
              }"
              placeholder="DD-MMM-YYYY"
              maxlength="11"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_PempToDate") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempResps') }"
          >
            <label for="app_PempResps">Responsibilities</label>
            <textarea
              id="app_PempResps"
              name="app_PempResps"
              v-model.trim="fdata.app_PempResps"
              v-validate.initial="{ required: true, max: 500 }"
              maxlength="500"
              rows="4"
              class="form-control"
            ></textarea>
            <span class="help-block">{{ errors.first("app_PempResps") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempNotice') }"
          >
            <label for="app_PempNotice">Notice required</label>
            <input
              id="app_PempNotice"
              name="app_PempNotice"
              v-model.trim="fdata.app_PempNotice"
              v-validate="{ max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_PempNotice") }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">
            Employment history: Present / most recent employer's address
          </h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempAddress1') }"
          >
            <label for="app_PempAddress1"
              >House number / First line of address</label
            >
            <input
              id="app_PempAddress1"
              name="app_PempAddress1"
              v-model.trim="fdata.app_PempAddress1"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_PempAddress1")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempAddress2') }"
          >
            <label for="app_PempAddress2">Street / Road</label>
            <input
              id="app_PempAddress2"
              name="app_PempAddress2"
              v-model.trim="fdata.app_PempAddress2"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_PempAddress2")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempAddress3') }"
          >
            <label for="app_PempAddress3">Locality</label>
            <input
              id="app_PempAddress3"
              name="app_PempAddress3"
              v-model.trim="fdata.app_PempAddress3"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_PempAddress3")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempTown') }"
          >
            <label for="app_PempTown">Town / City</label>
            <input
              id="app_PempTown"
              name="app_PempTown"
              v-model.trim="fdata.app_PempTown"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_PempTown") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempCounty') }"
          >
            <label for="app_PempCounty">County</label>
            <input
              id="app_PempCounty"
              name="app_PempCounty"
              v-model.trim="fdata.app_PempCounty"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_PempCounty") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempPostcode') }"
          >
            <label for="app_PempPostcode">Postcode</label>
            <input
              id="app_PempPostcode"
              name="app_PempPostcode"
              v-model.trim="fdata.app_PempPostcode"
              v-validate="{ max: 10, valid_uk_postcode: true }"
              maxlength="10"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_PempPostcode")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_PempCountry') }"
          >
            <label for="app_PempCountry">Country</label>
            <input
              id="app_PempCountry"
              name="app_PempCountry"
              v-model.trim="fdata.app_PempCountry"
              v-validate="{ max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_PempCountry")
            }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Previous employment</h3>
        </div>

        <div class="panel-body">
          <modal
            name="removal-alert-AppPrevEmp"
            :width="400"
            :height="'auto'"
            :clickToClose="false"
          >
            <div class="panel-primary">
              <div class="panel-heading modal-alert-heading">
                <span class="modal-alert-title">Information</span>
                <button
                  @click="closeAlertSubForm_AppPrevEmp"
                  type="button"
                  class="close pull-right"
                >
                  <span
                    class="fa fa-times-circle modal-alert-close-button-icon"
                  ></span>
                </button>
              </div>
              <div class="panel-body modal-alert-body">
                <div class="modal-alert-text-wrapper">
                  <span
                    class="fa fa-exclamation-circle modal-alert-icon"
                  ></span>
                  <span class="modal-alert-text">
                    <b>Previous employment:</b>
                    You are removing section {{ subformIndex + 1 }} of
                    {{ fdata.subForm_AppPrevEmp.length }}
                  </span>
                </div>
                <div class="modal-alert-footer">
                  <button
                    @click="removeSubFormSection_AppPrevEmp(subformIndex)"
                    type="button"
                    class="btn btn-success"
                  >
                    OK
                  </button>
                  <button
                    @click="closeAlertSubForm_AppPrevEmp"
                    type="button"
                    class="btn btn-success"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </modal>

          <modal
            name="removal-all-alert-AppPrevEmp"
            :width="400"
            :height="'auto'"
            :clickToClose="false"
          >
            <div class="panel-primary">
              <div class="panel-heading modal-alert-heading">
                <span class="modal-alert-title">Information</span>
                <button
                  @click="closeAlertAllSubForm_AppPrevEmp"
                  type="button"
                  class="close pull-right"
                >
                  <span
                    class="fa fa-times-circle modal-alert-close-button-icon"
                  ></span>
                </button>
              </div>
              <div class="panel-body modal-alert-body">
                <div class="modal-alert-text-wrapper">
                  <span
                    class="fa fa-exclamation-circle modal-alert-icon"
                  ></span>
                  <span class="modal-alert-text">
                    <b>Previous employment:</b> You are removing all sections
                  </span>
                </div>
                <div class="modal-alert-footer">
                  <button
                    @click="removeAllSubFormSection_AppPrevEmp"
                    type="button"
                    class="btn btn-success"
                  >
                    OK
                  </button>
                  <button
                    @click="closeAlertAllSubForm_AppPrevEmp"
                    type="button"
                    class="btn btn-success"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </modal>

          <div class="sub-section-header">
            <button
              @click="newSubFormSection_AppPrevEmp"
              type="button"
              class="btn btn-success"
            >
              Add new section
            </button>
            <button
              @click="removeAllSubFormSection_AppPrevEmp_Alert"
              type="button"
              class="btn btn-success"
            >
              Remove all sections
            </button>
          </div>
          <div
            class="sub-section-body"
            v-for="(sdata, i) in fdata.subForm_AppPrevEmp"
            :key="i"
          >
            <div class="sub-section-body-header">
              <div class="text-wrapper">
                <span class="title-text">Section</span>
                <span class="badge badge-text"
                  >{{ i + 1 }} of {{ fdata.subForm_AppPrevEmp.length }}</span
                >
              </div>
              <button
                @click="removeSubFormSection_AppPrevEmp_Alert(i)"
                type="button"
                class="btn btn-success"
              >
                Remove
              </button>
            </div>

            <div class="panel panel-primary">
              <div class="panel-body">
                <div
                  class="form-group"
                  v-bind:class="{ 'has-error': errors.has('app_PrevName' + i) }"
                >
                  <label :for="'app_PrevName' + i">Employer's name</label>
                  <input
                    :id="'app_PrevName' + i"
                    :name="'app_PrevName' + i"
                    v-model.trim="sdata.app_PrevName"
                    v-validate="{ max: 100 }"
                    maxlength="100"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.first("app_PrevName" + i)
                  }}</span>
                </div>

                <div
                  class="form-group"
                  v-bind:class="{ 'has-error': errors.has('app_PrevPos' + i) }"
                >
                  <label :for="'app_PrevPos' + i">Position Held</label>
                  <input
                    :id="'app_PrevPos' + i"
                    :name="'app_PrevPos' + i"
                    v-model.trim="sdata.app_PrevPos"
                    v-validate="{ max: 100 }"
                    maxlength="100"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.first("app_PrevPos" + i)
                  }}</span>
                </div>

                <div
                  class="form-group"
                  v-bind:class="{ 'has-error': errors.has('app_PrevSal' + i) }"
                >
                  <label :for="'app_PrevSal' + i">Salary</label>
                  <input
                    :id="'app_PrevSal' + i"
                    :name="'app_PrevSal' + i"
                    v-model.trim="sdata.app_PrevSal"
                    v-validate="{ decimal: 2, max: 10 }"
                    maxlength="10"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.first("app_PrevSal" + i)
                  }}</span>
                </div>

                <div
                  class="form-group"
                  v-bind:class="{
                    'has-error': errors.has('app_PrevSalUnit' + i),
                  }"
                >
                  <label :for="'app_PrevSalUnit' + i">Salary frequency</label>
                  <select
                    :id="'app_PrevSalUnit' + i"
                    :name="'app_PrevSalUnit' + i"
                    v-model="sdata.app_PrevSalUnit"
                    class="form-control"
                  >
                    <option></option>
                    <option value="1">Weekly</option>
                    <option value="2">Monthly</option>
                    <option value="3">Yearly</option>
                  </select>
                  <span class="help-block">{{
                    errors.first("app_PrevSalUnit" + i)
                  }}</span>
                </div>

                <div
                  class="form-group"
                  v-bind:class="{
                    'has-error': errors.has('app_PrevFromDate' + i),
                  }"
                >
                  <label :for="'app_PrevFromDate' + i">From: (date)</label>
                  <input
                    :id="'app_PrevFromDate' + i"
                    :name="'app_PrevFromDate' + i"
                    v-model.trim="sdata.app_PrevFromDate"
                    v-validate="{
                      valid_date_format: true,
                      current_date_compare: { opt: 'lteq' },
                    }"
                    placeholder="DD-MMM-YYYY"
                    maxlength="11"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.first("app_PrevFromDate" + i)
                  }}</span>
                </div>

                <div
                  class="form-group"
                  v-bind:class="{
                    'has-error': errors.has('app_PrevToDate' + i),
                  }"
                >
                  <label :for="'app_PrevToDate' + i">To: (date)</label>
                  <input
                    :id="'app_PrevToDate' + i"
                    :name="'app_PrevToDate' + i"
                    v-model.trim="sdata.app_PrevToDate"
                    v-validate="{ valid_date_format: true }"
                    placeholder="DD-MMM-YYYY"
                    maxlength="11"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.first("app_PrevToDate" + i)
                  }}</span>
                </div>

                <div
                  class="form-group"
                  v-bind:class="{
                    'has-error': errors.has('app_PrevReasons' + i),
                  }"
                >
                  <label :for="'app_PrevReasons' + i"
                    >Reasons for leaving</label
                  >
                  <textarea
                    :id="'app_PrevReasons' + i"
                    :name="'app_PrevReasons' + i"
                    v-model.trim="sdata.app_PrevReasons"
                    v-validate="{ max: 500 }"
                    maxlength="500"
                    rows="4"
                    class="form-control"
                  ></textarea>
                  <span class="help-block">{{
                    errors.first("app_PrevReasons" + i)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Education</h3>
        </div>

        <div class="panel-body">
          <modal
            name="removal-alert-AppEducation"
            :width="400"
            :height="'auto'"
            :clickToClose="false"
          >
            <div class="panel-primary">
              <div class="panel-heading modal-alert-heading">
                <span class="modal-alert-title">Information</span>
                <button
                  @click="closeAlertSubForm_AppEducation"
                  type="button"
                  class="close pull-right"
                >
                  <span
                    class="fa fa-times-circle modal-alert-close-button-icon"
                  ></span>
                </button>
              </div>
              <div class="panel-body modal-alert-body">
                <div class="modal-alert-text-wrapper">
                  <span
                    class="fa fa-exclamation-circle modal-alert-icon"
                  ></span>
                  <span class="modal-alert-text">
                    <b>Education:</b>
                    You are removing section {{ subformIndex + 1 }} of
                    {{ fdata.subForm_AppEducation.length }}
                  </span>
                </div>
                <div class="modal-alert-footer">
                  <button
                    @click="removeSubFormSection_AppEducation(subformIndex)"
                    type="button"
                    class="btn btn-success"
                  >
                    OK
                  </button>
                  <button
                    @click="closeAlertSubForm_AppEducation"
                    type="button"
                    class="btn btn-success"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </modal>

          <modal
            name="removal-all-alert-AppEducation"
            :width="400"
            :height="'auto'"
            :clickToClose="false"
          >
            <div class="panel-primary">
              <div class="panel-heading modal-alert-heading">
                <span class="modal-alert-title">Information</span>
                <button
                  @click="closeAlertAllSubForm_AppEducation"
                  type="button"
                  class="close pull-right"
                >
                  <span
                    class="fa fa-times-circle modal-alert-close-button-icon"
                  ></span>
                </button>
              </div>
              <div class="panel-body modal-alert-body">
                <div class="modal-alert-text-wrapper">
                  <span
                    class="fa fa-exclamation-circle modal-alert-icon"
                  ></span>
                  <span class="modal-alert-text">
                    <b>Education:</b> You are removing all sections
                  </span>
                </div>
                <div class="modal-alert-footer">
                  <button
                    @click="removeAllSubFormSection_AppEducation"
                    type="button"
                    class="btn btn-success"
                  >
                    OK
                  </button>
                  <button
                    @click="closeAlertAllSubForm_AppEducation"
                    type="button"
                    class="btn btn-success"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </modal>

          <div class="sub-section-header">
            <button
              @click="newSubFormSection_AppEducation"
              type="button"
              class="btn btn-success"
            >
              Add new section
            </button>
            <button
              @click="removeAllSubFormSection_AppEducation_Alert"
              type="button"
              class="btn btn-success"
            >
              Remove all sections
            </button>
          </div>
          <div
            class="sub-section-body"
            v-for="(sdata, i) in fdata.subForm_AppEducation"
            :key="i"
          >
            <div class="sub-section-body-header">
              <div class="text-wrapper">
                <span class="title-text">Section</span>
                <span class="badge badge-text"
                  >{{ i + 1 }} of {{ fdata.subForm_AppEducation.length }}</span
                >
              </div>
              <button
                @click="removeSubFormSection_AppEducation_Alert(i)"
                type="button"
                class="btn btn-success"
              >
                Remove
              </button>
            </div>

            <div class="panel panel-primary">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Please give details of your education since age 11 and
                  qualifications obtained
                </h3>
              </div>

              <div class="panel-body">
                <div
                  class="form-group"
                  v-bind:class="{ 'has-error': errors.has('app_EduOrg' + i) }"
                >
                  <label :for="'app_EduOrg' + i"
                    >School / College / University</label
                  >
                  <input
                    :id="'app_EduOrg' + i"
                    :name="'app_EduOrg' + i"
                    v-model.trim="sdata.app_EduOrg"
                    v-validate="{ max: 100 }"
                    maxlength="100"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.first("app_EduOrg" + i)
                  }}</span>
                </div>

                <div
                  class="form-group"
                  v-bind:class="{
                    'has-error': errors.has('app_EduFromDate' + i),
                  }"
                >
                  <label :for="'app_EduFromDate' + i">From: (date)</label>
                  <input
                    :id="'app_EduFromDate' + i"
                    :name="'app_EduFromDate' + i"
                    v-model.trim="sdata.app_EduFromDate"
                    v-validate="{
                      valid_date_format: true,
                      current_date_compare: { opt: 'lteq' },
                    }"
                    placeholder="DD-MMM-YYYY"
                    maxlength="11"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.first("app_EduFromDate" + i)
                  }}</span>
                </div>

                <div
                  class="form-group"
                  v-bind:class="{
                    'has-error': errors.has('app_EduToDate' + i),
                  }"
                >
                  <label :for="'app_EduToDate' + i">To: (date)</label>
                  <input
                    :id="'app_EduToDate' + i"
                    :name="'app_EduToDate' + i"
                    v-model.trim="sdata.app_EduToDate"
                    v-validate="{ valid_date_format: true }"
                    placeholder="DD-MMM-YYYY"
                    maxlength="11"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.first("app_EduToDate" + i)
                  }}</span>
                </div>

                <div
                  class="form-group"
                  v-bind:class="{
                    'has-error': errors.has('app_EduResults' + i),
                  }"
                >
                  <label :for="'app_EduResults' + i"
                    >Results / qualification</label
                  >
                  <textarea
                    :id="'app_EduResults' + i"
                    :name="'app_EduResults' + i"
                    v-model.trim="sdata.app_EduResults"
                    v-validate="{ max: 500 }"
                    maxlength="500"
                    rows="4"
                    class="form-control"
                  ></textarea>
                  <span class="help-block">{{
                    errors.first("app_EduResults" + i)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Training</h3>
        </div>

        <div class="panel-body">
          <modal
            name="removal-alert-AppTraining"
            :width="400"
            :height="'auto'"
            :clickToClose="false"
          >
            <div class="panel-primary">
              <div class="panel-heading modal-alert-heading">
                <span class="modal-alert-title">Information</span>
                <button
                  @click="closeAlertSubForm_AppTraining"
                  type="button"
                  class="close pull-right"
                >
                  <span
                    class="fa fa-times-circle modal-alert-close-button-icon"
                  ></span>
                </button>
              </div>
              <div class="panel-body modal-alert-body">
                <div class="modal-alert-text-wrapper">
                  <span
                    class="fa fa-exclamation-circle modal-alert-icon"
                  ></span>
                  <span class="modal-alert-text">
                    <b>Training:</b>
                    You are removing section {{ subformIndex + 1 }} of
                    {{ fdata.subForm_AppTraining.length }}
                  </span>
                </div>
                <div class="modal-alert-footer">
                  <button
                    @click="removeSubFormSection_AppTraining(subformIndex)"
                    type="button"
                    class="btn btn-success"
                  >
                    OK
                  </button>
                  <button
                    @click="closeAlertSubForm_AppTraining"
                    type="button"
                    class="btn btn-success"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </modal>

          <modal
            name="removal-all-alert-AppTraining"
            :width="400"
            :height="'auto'"
            :clickToClose="false"
          >
            <div class="panel-primary">
              <div class="panel-heading modal-alert-heading">
                <span class="modal-alert-title">Information</span>
                <button
                  @click="closeAlertAllSubForm_AppTraining"
                  type="button"
                  class="close pull-right"
                >
                  <span
                    class="fa fa-times-circle modal-alert-close-button-icon"
                  ></span>
                </button>
              </div>
              <div class="panel-body modal-alert-body">
                <div class="modal-alert-text-wrapper">
                  <span
                    class="fa fa-exclamation-circle modal-alert-icon"
                  ></span>
                  <span class="modal-alert-text">
                    <b>Training:</b> You are removing all sections
                  </span>
                </div>
                <div class="modal-alert-footer">
                  <button
                    @click="removeAllSubFormSection_AppTraining"
                    type="button"
                    class="btn btn-success"
                  >
                    OK
                  </button>
                  <button
                    @click="closeAlertAllSubForm_AppTraining"
                    type="button"
                    class="btn btn-success"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </modal>

          <div class="sub-section-header">
            <button
              @click="newSubFormSection_AppTraining"
              type="button"
              class="btn btn-success"
            >
              Add new section
            </button>
            <button
              @click="removeAllSubFormSection_AppTraining_Alert"
              type="button"
              class="btn btn-success"
            >
              Remove all sections
            </button>
          </div>
          <div
            class="sub-section-body"
            v-for="(sdata, i) in fdata.subForm_AppTraining"
            :key="i"
          >
            <div class="sub-section-body-header">
              <div class="text-wrapper">
                <span class="title-text">Section</span>
                <span class="badge badge-text"
                  >{{ i + 1 }} of {{ fdata.subForm_AppTraining.length }}</span
                >
              </div>
              <button
                @click="removeSubFormSection_AppTraining_Alert(i)"
                type="button"
                class="btn btn-success"
              >
                Remove
              </button>
            </div>

            <div class="panel panel-primary">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Please give details of any relevant training and dates
                  obtained
                </h3>
              </div>

              <div class="panel-body">
                <div
                  class="form-group"
                  v-bind:class="{ 'has-error': errors.has('app_TrnOrg' + i) }"
                >
                  <label :for="'app_TrnOrg' + i">Training</label>
                  <input
                    :id="'app_TrnOrg' + i"
                    :name="'app_TrnOrg' + i"
                    v-model.trim="sdata.app_TrnOrg"
                    v-validate="{ max: 100 }"
                    maxlength="100"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.first("app_TrnOrg" + i)
                  }}</span>
                </div>

                <div
                  class="form-group"
                  v-bind:class="{
                    'has-error': errors.has('app_TrnDateObtained' + i),
                  }"
                >
                  <label :for="'app_TrnDateObtained' + i">Date obtained</label>
                  <input
                    :id="'app_TrnDateObtained' + i"
                    :name="'app_TrnDateObtained' + i"
                    v-model.trim="sdata.app_TrnDateObtained"
                    v-validate="{
                      valid_date_format: true,
                      current_date_compare: { opt: 'lteq' },
                    }"
                    placeholder="DD-MMM-YYYY"
                    maxlength="11"
                    class="form-control"
                  />
                  <span class="help-block">{{
                    errors.first("app_TrnDateObtained" + i)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Additional information</h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_AddInfo') }"
          >
            <label for="app_AddInfo"
              >Is there anything else that you would like to say in support of
              your application (max. 500 words)</label
            >
            <textarea
              id="app_AddInfo"
              name="app_AddInfo"
              v-model.trim="fdata.app_AddInfo"
              v-validate="{ max: 500 }"
              maxlength="500"
              rows="4"
              class="form-control"
            ></textarea>
            <span class="help-block">{{ errors.first("app_AddInfo") }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">First Reference</h3>
        </div>

        <div class="panel-body">
          <p>
            References: Please give details of two referees, the first of which
            should be your most recent employer. The second should be a personal
            reference from a person (not a relative or friend) who has known you
            for at least three years and is a professional (e.g. teacher, nurse,
            Minister of Religion).
          </p>
          <hr />
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefName') }"
          >
            <label for="app_RefName">Name</label>
            <input
              id="app_RefName"
              name="app_RefName"
              v-model.trim="fdata.app_RefName"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefName") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefOcu') }"
          >
            <label for="app_RefOcu">Occupation</label>
            <input
              id="app_RefOcu"
              name="app_RefOcu"
              v-model.trim="fdata.app_RefOcu"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefOcu") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefCompName') }"
          >
            <label for="app_RefCompName">Company Name</label>
            <input
              id="app_RefCompName"
              name="app_RefCompName"
              v-model.trim="fdata.app_RefCompName"
              v-validate="{ max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_RefCompName")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefTel') }"
          >
            <label for="app_RefTel">Mobile / telephone number</label>
            <input
              id="app_RefTel"
              name="app_RefTel"
              v-model.trim="fdata.app_RefTel"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefTel") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefRel') }"
          >
            <label for="app_RefRel">Relationship to applicant</label>
            <input
              id="app_RefRel"
              name="app_RefRel"
              v-model.trim="fdata.app_RefRel"
              v-validate.initial="{ required: true, max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefRel") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefTakeNow') }"
          >
            <label for="app_RefTakeNow"
              >May we take up this reference now</label
            >
            <select
              id="app_RefTakeNow"
              name="app_RefTakeNow"
              v-model="fdata.app_RefTakeNow"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
            <span class="help-block">{{ errors.first("app_RefTakeNow") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefAddress1') }"
          >
            <label for="app_RefAddress1"
              >House number / First line of address</label
            >
            <input
              id="app_RefAddress1"
              name="app_RefAddress1"
              v-model.trim="fdata.app_RefAddress1"
              v-validate.initial="{ required: true, max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_RefAddress1")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefAddress2') }"
          >
            <label for="app_RefAddress2">Street / Road</label>
            <input
              id="app_RefAddress2"
              name="app_RefAddress2"
              v-model.trim="fdata.app_RefAddress2"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_RefAddress2")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefAddress3') }"
          >
            <label for="app_RefAddress3">Locality</label>
            <input
              id="app_RefAddress3"
              name="app_RefAddress3"
              v-model.trim="fdata.app_RefAddress3"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_RefAddress3")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefTown') }"
          >
            <label for="app_RefTown">Town/City</label>
            <input
              id="app_RefTown"
              name="app_RefTown"
              v-model.trim="fdata.app_RefTown"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefTown") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefCounty') }"
          >
            <label for="app_RefCounty">County</label>
            <input
              id="app_RefCounty"
              name="app_RefCounty"
              v-model.trim="fdata.app_RefCounty"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefCounty") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefPostcode') }"
          >
            <label for="app_RefPostcode">Postcode</label>
            <input
              id="app_RefPostcode"
              name="app_RefPostcode"
              v-model.trim="fdata.app_RefPostcode"
              v-validate.initial="{
                required: true,
                max: 10,
                valid_uk_postcode: true,
              }"
              maxlength="10"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_RefPostcode")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefCountry') }"
          >
            <label for="app_RefCountry">Country</label>
            <select
              id="app_RefCountry"
              name="app_RefCountry"
              v-model="fdata.app_RefCountry"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">United Kingdom (UK)</option>
            </select>
            <span class="help-block">{{ errors.first("app_RefCountry") }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Second Reference</h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefName2') }"
          >
            <label for="app_RefName2">Name</label>
            <input
              id="app_RefName2"
              name="app_RefName2"
              v-model.trim="fdata.app_RefName2"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefName2") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefOcu2') }"
          >
            <label for="app_RefOcu2">Occupation</label>
            <input
              id="app_RefOcu2"
              name="app_RefOcu2"
              v-model.trim="fdata.app_RefOcu2"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefOcu2") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefCompName2') }"
          >
            <label for="app_RefCompName2">Company Name</label>
            <input
              id="app_RefCompName2"
              name="app_RefCompName2"
              v-model.trim="fdata.app_RefCompName2"
              v-validate="{ max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_RefCompName2")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefTel2') }"
          >
            <label for="app_RefTel2">Mobile / telephone number</label>
            <input
              id="app_RefTel2"
              name="app_RefTel2"
              v-model.trim="fdata.app_RefTel2"
              v-validate.initial="{ required: true, max: 100 }"
              maxlength="100"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefTel2") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefRel2') }"
          >
            <label for="app_RefRel2">Relationship to applicant</label>
            <input
              id="app_RefRel2"
              name="app_RefRel2"
              v-model.trim="fdata.app_RefRel2"
              v-validate.initial="{ required: true, max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefRel2") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefTakeNow2') }"
          >
            <label for="app_RefTakeNow2"
              >May we take up this reference now</label
            >
            <select
              id="app_RefTakeNow2"
              name="app_RefTakeNow2"
              v-model="fdata.app_RefTakeNow2"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
            <span class="help-block">{{
              errors.first("app_RefTakeNow2")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefAddress12') }"
          >
            <label for="app_RefAddress12"
              >House number / First line of address</label
            >
            <input
              id="app_RefAddress12"
              name="app_RefAddress12"
              v-model.trim="fdata.app_RefAddress12"
              v-validate.initial="{ required: true, max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_RefAddress12")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefAddress22') }"
          >
            <label for="app_RefAddress22">Street / Road</label>
            <input
              id="app_RefAddress22"
              name="app_RefAddress22"
              v-model.trim="fdata.app_RefAddress22"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_RefAddress22")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefAddress32') }"
          >
            <label for="app_RefAddress32">Locality</label>
            <input
              id="app_RefAddress32"
              name="app_RefAddress32"
              v-model.trim="fdata.app_RefAddress32"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_RefAddress32")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefTown2') }"
          >
            <label for="app_RefTown2">Town/City</label>
            <input
              id="app_RefTown2"
              name="app_RefTown2"
              v-model.trim="fdata.app_RefTown2"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefTown2") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefCounty2') }"
          >
            <label for="app_RefCounty2">County</label>
            <input
              id="app_RefCounty2"
              name="app_RefCounty2"
              v-model.trim="fdata.app_RefCounty2"
              v-validate="{ max: 50 }"
              maxlength="50"
              class="form-control"
            />
            <span class="help-block">{{ errors.first("app_RefCounty2") }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefPostcode2') }"
          >
            <label for="app_RefPostcode2">Postcode</label>
            <input
              id="app_RefPostcode2"
              name="app_RefPostcode2"
              v-model.trim="fdata.app_RefPostcode2"
              v-validate.initial="{
                required: true,
                max: 10,
                valid_uk_postcode: true,
              }"
              maxlength="10"
              class="form-control"
            />
            <span class="help-block">{{
              errors.first("app_RefPostcode2")
            }}</span>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_RefCountry2') }"
          >
            <label for="app_RefCountry2">Country</label>
            <select
              id="app_RefCountry2"
              name="app_RefCountry2"
              v-model="fdata.app_RefCountry2"
              v-validate.initial="{ required: true }"
              class="form-control"
            >
              <option></option>
              <option value="1">United Kingdom (UK)</option>
            </select>
            <span class="help-block">{{
              errors.first("app_RefCountry2")
            }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Declaration</h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_DecInfoTrue') }"
          >
            <label for="app_DecInfoTrue"
              >I confirm that the information contained in this application is
              true and complete. I accept that providing deliberately false
              information could result in my dismissal.</label
            >
            <select
              id="app_DecInfoTrue"
              name="app_DecInfoTrue"
              v-model="fdata.app_DecInfoTrue"
              v-validate.initial="{
                required: true,
                lookup_compare: {
                  opt: 'eq',
                  targetValue: 2,
                  dataType: 'number',
                  msg: 'This question must be YES',
                },
              }"
              class="form-control"
            >
              <option></option>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
            <span class="help-block">{{
              errors.first("app_DecInfoTrue")
            }}</span>
          </div>
        </div>
      </div>

      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title">Health declaration</h3>
        </div>

        <div class="panel-body">
          <div
            class="form-group"
            v-bind:class="{ 'has-error': errors.has('app_DecWell') }"
          >
            <label for="app_DecWell"
              >I confirm that I consider myself to be well enough in my physical
              and mental health to cope with the demands of the post I have
              applied for with MOSHA CARE SERVICE LTD.</label
            >
            <select
              id="app_DecWell"
              name="app_DecWell"
              v-model="fdata.app_DecWell"
              v-validate.initial="{
                required: true,
                lookup_compare: {
                  opt: 'eq',
                  targetValue: 2,
                  dataType: 'number',
                  msg: 'This question must be YES',
                },
              }"
              class="form-control"
            >
              <option></option>
              <option value="1">No</option>
              <option value="2">Yes</option>
            </select>
            <span class="help-block">{{ errors.first("app_DecWell") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <modal
        name="alert-dialog"
        :width="400"
        :height="'auto'"
        :clickToClose="false"
      >
        <div class="panel-primary">
          <div class="panel-heading modal-alert-heading">
            <span class="modal-alert-title">Information</span>
            <button
              @click="closeAlertDialog"
              type="button"
              class="close pull-right"
            >
              <span
                class="fa fa-times-circle modal-alert-close-button-icon"
              ></span>
            </button>
          </div>
          <div class="panel-body modal-alert-body">
            <div class="modal-alert-text-wrapper">
              <span class="fa fa-exclamation-circle modal-alert-icon"></span>
              <span class="modal-alert-text">{{ alertMessage }}</span>
            </div>
            <div class="modal-alert-footer">
              <button
                @click="closeAlertDialog"
                type="button"
                class="btn btn-success"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </modal>

      <div class="btn-submit-wrapper">
        <div v-if="showAnimation" class="cc-animation">
          <span class="fa fa-spinner fa-spin fa-3x fa-fw"></span>
          <br />
          <span>Form submission in progress</span>
        </div>
        <div class="cc-submit-btn-wrapper">
          <button
            v-on:click="submitForm"
            v-bind:disabled="showAnimation"
            type="submit"
            class="btn btn-primary"
            style="width: 200px;"
          >
            Submit Application
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {    
  },
  data() {
    return {
      fdata: {
        app_Position: "",
        app_PositionText: "",
        app_Title: "",
        app_TitleText: "",
        app_FirstName: "",
        app_MiddleName: "",
        app_Surname: "",
        app_DateOfBirth: "",
        app_MobileNumber: "",
        app_Email: "",
        app_Address1: "",
        app_Address2: "",
        app_Address3: "",
        app_Town: "",
        app_County: "",
        app_Postcode: "",
        app_Country: "",
        app_EmpNI: "",
        app_NextOfKin: "",
        app_NextOfKinRel: "",
        app_NextOfKinTel: "",
        app_EmpTemp: "",
        app_EmpRegPartTime: "",
        app_EmpRegFullTime: "",
        app_AvMonday: "",
        app_AvTuesday: "",
        app_AvWednesday: "",
        app_AvThursday: "",
        app_AvFriday: "",
        app_AvSaturday: "",
        app_AvSunday: "",
        app_AvStartDate: "",
        app_AvCanWorkEv: "",
        app_AvDLtype: "",
        app_AvOwnTpt: "",
        app_AvDBScheck: "",
        app_AvDBSref: "",
        app_PempName: "",
        app_PempTel: "",
        app_PempBType: "",
        app_PempPos: "",
        app_PempFromDate: "",
        app_PempToDate: "",
        app_PempResps: "",
        app_PempNotice: "",
        app_PempAddress1: "",
        app_PempAddress2: "",
        app_PempAddress3: "",
        app_PempTown: "",
        app_PempCounty: "",
        app_PempPostcode: "",
        app_PempCountry: "",
        app_AddInfo: "",
        app_RefName: "",
        app_RefOcu: "",
        app_RefCompName: "",
        app_RefTel: "",
        app_RefRel: "",
        app_RefTakeNow: "",
        app_RefAddress1: "",
        app_RefAddress2: "",
        app_RefAddress3: "",
        app_RefTown: "",
        app_RefCounty: "",
        app_RefPostcode: "",
        app_RefCountry: "",
        app_RefName2: "",
        app_RefOcu2: "",
        app_RefCompName2: "",
        app_RefTel2: "",
        app_RefRel2: "",
        app_RefTakeNow2: "",
        app_RefAddress12: "",
        app_RefAddress22: "",
        app_RefAddress32: "",
        app_RefTown2: "",
        app_RefCounty2: "",
        app_RefPostcode2: "",
        app_RefCountry2: "",
        app_DecInfoTrue: "",
        app_DecWell: "",
        subForm_AppPrevEmp: [],
        subForm_AppEducation: [],
        subForm_AppTraining: [],
      },
      showAnimation: false,
      subformIndex: 0,
      alertMessage: "",
    };
  },
  methods: {
    closeAlertDialog: function() {
      this.$modal.hide("alert-dialog");
    },
    submitForm: function() {
      let self = this;
      this.$validator.validateAll().then(function(result) {
        if (!result) {
          self.alertMessage = "Form contains validation warnings";
          self.$modal.show("alert-dialog");
        } else {
          self.showAnimation = true;
          axios
            .post("Application/SubmitForm", self.fdata)
            .then((r) => {
              if (r.data == 0) {
                //successful
                self.resetFormData(self.fdata);
                self.alertMessage = "Your form has been submitted successfully";
                self.$modal.show("alert-dialog");
              } else {
                //form is invalid
                self.alertMessage = "Form contains validation warnings";
                self.$modal.show("alert-dialog");
              }
              self.showAnimation = false;
            })
            .catch(() => {
              self.showAnimation = false;
            });
        }
      });
    },
    //SubForm Logic
    //subForm: AppPrevEmp-Previous employment
    newSubFormSection_AppPrevEmp: function() {
      this.fdata.subForm_AppPrevEmp.push({
        app_PrevName: "",
        app_PrevPos: "",
        app_PrevSal: "",
        app_PrevSalUnit: "",
        app_PrevFromDate: "",
        app_PrevToDate: "",
        app_PrevReasons: "",
      });
    },
    removeAllSubFormSection_AppPrevEmp_Alert: function() {
      if (this.fdata.subForm_AppPrevEmp.length > 0) {
        this.$modal.show("removal-all-alert-AppPrevEmp");
      }
    },
    removeAllSubFormSection_AppPrevEmp: function() {
      this.fdata.subForm_AppPrevEmp = [];
      this.$modal.hide("removal-all-alert-AppPrevEmp");
    },
    closeAlertAllSubForm_AppPrevEmp: function() {
      this.$modal.hide("removal-all-alert-AppPrevEmp");
    },
    removeSubFormSection_AppPrevEmp_Alert: function(index) {
      this.$modal.show("removal-alert-AppPrevEmp");
      this.subformIndex = index;
    },
    removeSubFormSection_AppPrevEmp: function(index) {
      this.fdata.subForm_AppPrevEmp.splice(index, 1);
      this.$modal.hide("removal-alert-AppPrevEmp");
    },
    closeAlertSubForm_AppPrevEmp: function() {
      this.$modal.hide("removal-alert-AppPrevEmp");
    },
    //subForm: AppEducation-Education
    newSubFormSection_AppEducation: function() {
      this.fdata.subForm_AppEducation.push({
        app_EduOrg: "",
        app_EduFromDate: "",
        app_EduToDate: "",
        app_EduResults: "",
      });
    },
    removeAllSubFormSection_AppEducation_Alert: function() {
      if (this.fdata.subForm_AppEducation.length > 0) {
        this.$modal.show("removal-all-alert-AppEducation");
      }
    },
    removeAllSubFormSection_AppEducation: function() {
      this.fdata.subForm_AppEducation = [];
      this.$modal.hide("removal-all-alert-AppEducation");
    },
    closeAlertAllSubForm_AppEducation: function() {
      this.$modal.hide("removal-all-alert-AppEducation");
    },
    removeSubFormSection_AppEducation_Alert: function(index) {
      this.$modal.show("removal-alert-AppEducation");
      this.subformIndex = index;
    },
    removeSubFormSection_AppEducation: function(index) {
      this.fdata.subForm_AppEducation.splice(index, 1);
      this.$modal.hide("removal-alert-AppEducation");
    },
    closeAlertSubForm_AppEducation: function() {
      this.$modal.hide("removal-alert-AppEducation");
    },
    //subForm: AppTraining-Training
    newSubFormSection_AppTraining: function() {
      this.fdata.subForm_AppTraining.push({
        app_TrnOrg: "",
        app_TrnDateObtained: "",
      });
    },
    removeAllSubFormSection_AppTraining_Alert: function() {
      if (this.fdata.subForm_AppTraining.length > 0) {
        this.$modal.show("removal-all-alert-AppTraining");
      }
    },
    removeAllSubFormSection_AppTraining: function() {
      this.fdata.subForm_AppTraining = [];
      this.$modal.hide("removal-all-alert-AppTraining");
    },
    closeAlertAllSubForm_AppTraining: function() {
      this.$modal.hide("removal-all-alert-AppTraining");
    },
    removeSubFormSection_AppTraining_Alert: function(index) {
      this.$modal.show("removal-alert-AppTraining");
      this.subformIndex = index;
    },
    removeSubFormSection_AppTraining: function(index) {
      this.fdata.subForm_AppTraining.splice(index, 1);
      this.$modal.hide("removal-alert-AppTraining");
    },
    closeAlertSubForm_AppTraining: function() {
      this.$modal.hide("removal-alert-AppTraining");
    },
    resetSubFormData: function() {
      this.newSubFormSection_AppPrevEmp();
      this.newSubFormSection_AppEducation();
      this.newSubFormSection_AppTraining();
    },
    resetFormData: function(fdata) {
      Object.assign(fdata, {
        app_Position: "",
        app_PositionText: "",
        app_Title: "",
        app_TitleText: "",
        app_FirstName: "",
        app_MiddleName: "",
        app_Surname: "",
        app_DateOfBirth: "",
        app_MobileNumber: "",
        app_Email: "",
        app_Address1: "",
        app_Address2: "",
        app_Address3: "",
        app_Town: "",
        app_County: "",
        app_Postcode: "",
        app_Country: "",
        app_EmpNI: "",
        app_NextOfKin: "",
        app_NextOfKinRel: "",
        app_NextOfKinTel: "",
        app_EmpTemp: "",
        app_EmpRegPartTime: "",
        app_EmpRegFullTime: "",
        app_AvMonday: "",
        app_AvTuesday: "",
        app_AvWednesday: "",
        app_AvThursday: "",
        app_AvFriday: "",
        app_AvSaturday: "",
        app_AvSunday: "",
        app_AvStartDate: "",
        app_AvCanWorkEv: "",
        app_AvDLtype: "",
        app_AvOwnTpt: "",
        app_AvDBScheck: "",
        app_AvDBSref: "",
        app_PempName: "",
        app_PempTel: "",
        app_PempBType: "",
        app_PempPos: "",
        app_PempFromDate: "",
        app_PempToDate: "",
        app_PempResps: "",
        app_PempNotice: "",
        app_PempAddress1: "",
        app_PempAddress2: "",
        app_PempAddress3: "",
        app_PempTown: "",
        app_PempCounty: "",
        app_PempPostcode: "",
        app_PempCountry: "",
        app_AddInfo: "",
        app_RefName: "",
        app_RefOcu: "",
        app_RefCompName: "",
        app_RefTel: "",
        app_RefRel: "",
        app_RefTakeNow: "",
        app_RefAddress1: "",
        app_RefAddress2: "",
        app_RefAddress3: "",
        app_RefTown: "",
        app_RefCounty: "",
        app_RefPostcode: "",
        app_RefCountry: "",
        app_RefName2: "",
        app_RefOcu2: "",
        app_RefCompName2: "",
        app_RefTel2: "",
        app_RefRel2: "",
        app_RefTakeNow2: "",
        app_RefAddress12: "",
        app_RefAddress22: "",
        app_RefAddress32: "",
        app_RefTown2: "",
        app_RefCounty2: "",
        app_RefPostcode2: "",
        app_RefCountry2: "",
        app_DecInfoTrue: "",
        app_DecWell: "",
        subForm_AppPrevEmp: [],
        subForm_AppEducation: [],
        subForm_AppTraining: [],
      });
      this.resetSubFormData();
    },
  },
  computed: {
    disableCriteriaMet_app_PositionText: function() {
      if (
        this.fdata.app_Position != null &&
        [97, 98, 99].indexOf(+this.fdata.app_Position) != -1
      ) {
        return false;
      }
      return true;
    },
    disableCriteriaMet_app_TitleText: function() {
      if (
        this.fdata.app_Title != null &&
        [97].indexOf(+this.fdata.app_Title) != -1
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    disableCriteriaMet_app_PositionText: function() {
      this.fdata.app_PositionText = "";
    },
    disableCriteriaMet_app_TitleText: function() {
      this.fdata.app_TitleText = "";
    },
  },
  mounted: function() {
    this.resetSubFormData();
  },
};
</script>
